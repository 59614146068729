import { Box, Button, Checkbox, FormControlLabel, Modal, TextField, Typography } from "@mui/material";
import React from "react";

export default function DynamicKeepaGraphPopup(props) {
  const decRange = () => {
    props.setRange((prevValue) => Math.max(parseInt(prevValue) - 1, 1));
  };
  const incRange = () => {
    props.setRange((prevValue) => parseInt(prevValue) + 1);
  };
  const changeRange = (e) => {
    props.setRange(e.target.value);
  };
  const fixRange = (e) => {
    if (e.target.value < 1) {
      props.setRange(30);
    }
  };
  const getImageSource = () => {
    return `https://graph.keepa.com/?asin=${props.asin
      }&domain=com&salesrank=${+props.bsr_state}&amazon=${+props.az_state}&new=${+props.new_state}&bb=${+props.bb_state}&fba=${+props.fba_state}&range=${props.range_state}`;
  };
  const close = () => {
    props.setDKGAsin(null);
    localStorage.setItem("DKGbsr", JSON.stringify(props.bsr_state));
    localStorage.setItem("DKGaz", JSON.stringify(props.az_state));
    localStorage.setItem("DKGnew", JSON.stringify(props.new_state));
    localStorage.setItem("DKGbb", JSON.stringify(props.bb_state));
    localStorage.setItem("DKGfba", JSON.stringify(props.fba_state));
    localStorage.setItem("DKGrange", props.range_state);
  }

  return (
    <>
      <Modal open={props.asin !== null} onClose={close}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(230, 175, 10, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', marginBottom: 25, }}>
            Dynamic Keepa Graph
          </Typography>

          {/* Days range */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
            <Button
              variant="contained"
              onClick={decRange}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#E6AF0A',
                border: '2px solid #FCF8F3',
                height: '4vh',
                marginRight: 10,
              }}
            ><span>-</span>
            </Button>

            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
              <TextField
                id="rangeValue"
                type="text"
                value={props.range_state}
                onChange={changeRange}
                onBlur={fixRange}
                variant="outlined"
                size="small"
                sx={{ width: "3vw", fontSize: "1.1vw" }}
              />
              <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
                &nbsp;Days
              </Typography>
            </Box>

            <Button
              variant="contained"
              onClick={incRange}
              style={{
                textTransform: 'none',
                fontFamily: 'RobotoSlab',
                color: '#FCF8F3',
                borderRadius: '20px',
                backgroundColor: '#E6AF0A',
                border: '2px solid #FCF8F3',
                height: '4vh',
                marginLeft: 10,
              }}
            ><span>+</span>
            </Button>
          </Box>

          {/* Keepa Graph & Checkboxes */}
          <Box sx={{ display: 'flex', justifyContent: 'space-evenly', }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', }}>
              {/* Sales Rank */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.bsr_state}
                    sx={{ color: '#E6AF0A', '&.Mui-checked': { color: '#E6AF0A' }, transform: "scale(1.2)" }}
                    onChange={() => props.setBsr(!props.bsr_state)}
                  />
                }
                label={<strong style={{ fontFamily: 'RobotoSlab' }}>Sales Rank</strong>}
                labelPlacement="start"
              />
              {/* Amazon */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.az_state}
                    sx={{ color: '#E6AF0A', '&.Mui-checked': { color: '#E6AF0A' }, transform: "scale(1.2)" }}
                    onChange={() => props.setAz(!props.az_state)}
                  />
                }
                label={<strong style={{ fontFamily: 'RobotoSlab' }}>Amazon</strong>}
                labelPlacement="start"
              />
              {/* New */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.new_state}
                    sx={{ color: '#E6AF0A', '&.Mui-checked': { color: '#E6AF0A' }, transform: "scale(1.2)" }}
                    onChange={() => props.setNew(!props.new_state)}
                  />
                }
                label={<strong style={{ fontFamily: 'RobotoSlab' }}>New</strong>}
                labelPlacement="start"
              />
            </Box>

            {/* img */}
            <Box>
              <img
                loading="lazy"
                onClick={() => window.open(getImageSource(), "_blank")}
                src={getImageSource()}
                alt="keepahist"
                style={{ position: "relative", border: "1px solid gray", height: "100%" }}
              // className="disableHoverCssDynamicKeepa"
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', }}>
              {/* Buy Box */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.bb_state}
                    sx={{ color: '#E6AF0A', '&.Mui-checked': { color: '#E6AF0A' }, transform: "scale(1.2)" }}
                    onChange={() => props.setBb(!props.bb_state)}
                  />
                }
                label={<strong style={{ fontFamily: 'RobotoSlab' }}>Buy Box</strong>}
              />
              {/* New FBA */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.fba_state}
                    sx={{ color: '#E6AF0A', '&.Mui-checked': { color: '#E6AF0A' }, transform: "scale(1.2)" }}
                    onChange={() => props.setFba(!props.fba_state)}
                  />
                }
                label={<strong style={{ fontFamily: 'RobotoSlab' }}>New FBA</strong>}
              />
            </Box>
          </Box>

          {/* Close button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={close} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
