import { getUser } from "./authService";

export async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export async function addToFavoritesDB(product) {
  const favProduct = {...product, date: new Date().toLocaleDateString()};

  const obj = {email: getUser(), favProduct: favProduct};

  await fetch("https://server.nepeto.com/moa_add_to_favorites/", {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj)
  })
}

export async function removeFromFavoritesDB(asin) {
  const response = await fetch(`https://server.nepeto.com/moa_remove_from_favorites/${getUser()}/${asin}/`);
  const resData = await response.json();
}