import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from './components/Navbar';
import Amazon2Walmart from './pages/Amazon2Walmart/Amazon2Walmart';
import HomePage from './pages/Home/HomePage';
import LoginSignUp from './pages/Login/Login';
import './App.css';
import Favorites from './pages/Favorites/Favorites';
import { isUserAuthenticated } from './utils/authService';
import Pricing from './pages/Pricing/Pricing';
import Account from './pages/Account/Account';

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [isYearly, setIsYearly] = useState(false);

  useEffect(() => {
    setUserAuthenticated(isUserAuthenticated());
  }, []);

  return (
    <BrowserRouter>
      <Navbar isAuth={userAuthenticated} setIsAuth={setUserAuthenticated} setIsFlipped={setIsSignIn}/>
      <Routes>
        <Route path="/">
          <Route path="/" element={<HomePage />}/>
          <Route path="login" element={<LoginSignUp isAuth={userAuthenticated} setIsAuth={setUserAuthenticated} isFlipped={isSignIn} setIsFlipped={setIsSignIn} isYearly={isYearly}/>} />
          <Route path="favorites" element={<Favorites isMobile={isMobile} isAuth={userAuthenticated}/>} />
          <Route path="pricing" element={<Pricing isAuth={userAuthenticated} setIsFlipped={setIsSignIn} isYearly={isYearly} setIsYearly={setIsYearly}/>} />
          <Route path="account" element={<Account />} />
          <Route path="products" element={<Amazon2Walmart isMobile={isMobile} />}/>
          <Route
            path="*"
            element={
              <div
                style={{
                  textAlign: "center",
                  marginTop: "10vh",
                  marginBottom: "70vh",
                }}
              >
                Page not found.
              </div>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
