import React, { useState } from 'react';
import Draggable from 'react-draggable';

const DraggablePopup = (props) => {
  return (
    <div>
      <Draggable>
        <div
          style={{
            position: 'fixed',
            top: '100px',
            right: '100px',
            width: '408px',
            height: '765px',
            border: '1px solid #ccc',
            backgroundColor: '#FFC910',
            cursor: "move",
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            zIndex: '1000',
          }}
        >
          <div style={{ marginBottom: '2vh' }}>
            <button onClick={() => props.setWmitemId("")} style={{ padding: '5px 10px', cursor: 'pointer' }}>
              Close
            </button>
            <span style={{ float: "right" }}>Click(Hold) and drag to move</span>
          </div>
          <iframe
            src={`https://app.getmarter.com/?walmartId=${props.itemId}&price=${props.price}`}
            title="Marter"
            style={{
              width: '100%',
              height: '700px',
              border: '1px solid black',
            }}
          />
        </div>
      </Draggable>
    </div>
  );
};

export default DraggablePopup;
