import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Input, InputAdornment, InputLabel, LinearProgress, Tooltip, tooltipClasses, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import './Products.css';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DownloadIcon from '@mui/icons-material/Download';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { addToFavoritesDB, removeFromFavoritesDB } from "../utils/helpers";
import DynamicKeepaGraphPopup from "./DynamicKeepaGraphPopup";
import styled from "@emotion/styled";
import DraggablePopup from "./DraggableIframe";

const SortableTableHeader = (props) => {
  const handleSort = async () => {
    if (props.filters.hasOwnProperty("sortBy") &&
      props.filters["sortBy"] === props.sortKey) {
      return
    }
    props.setFilters((filters) => ({
      ...filters,
      sortBy: props.sortKey,
    }));
  };

  return (
    <th className={window.location.pathname === '/favorites' ? "" : "sortColumn"} onClick={handleSort}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          {props.columnName}
          {props.filters.sortBy === props.sortKey && (
            <ArrowDownwardOutlinedIcon style={{ fontSize: '100%', fontWeight: 'bold', marginLeft: '4px' }} />
          )}
        </div>
      </div>
    </th>
  );
};

const CostumTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCF8F3',
    color: '#E6AF0A',
    maxWidth: "10vw",
    border: '2px solid #E6AF0A',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#E6AF0A',
  },
});

function ProductsTable(props) {
  const [bsr_state, setBsr] = useState(localStorage.getItem("DKGbsr") === null ? true : JSON.parse(localStorage.getItem("DKGbsr")));
  const [az_state, setAz] = useState(localStorage.getItem("DKGaz") === null ? true : JSON.parse(localStorage.getItem("DKGaz")));
  const [new_state, setNew] = useState(localStorage.getItem("DKGnew") === null ? true : JSON.parse(localStorage.getItem("DKGnew")));
  const [bb_state, setBb] = useState(localStorage.getItem("DKGbb") === null ? true : JSON.parse(localStorage.getItem("DKGbb")));
  const [fba_state, setFba] = useState(localStorage.getItem("DKGfba") === null ? true : JSON.parse(localStorage.getItem("DKGfba")));
  const [range_state, setRange] = useState(localStorage.getItem("DKGrange") === null ? 90 : localStorage.getItem("DKGrange"));
  const [DKGasin, setDKGAsin] = useState(null);
  const [sfChecked, setSFChecked] = useState(false);
  const [wmitemId, setWmitemId] = useState("");
  const [wmitemPrice, setWmitemPrice] = useState(0);

  const [render, setRender] = useState(0);
  const [favorites, setFavorites] = useState({});

  useEffect(() => {
    if (window.location.pathname === '/favorites' && props.data.length > 0) {
      const initialFavorites = props.data.reduce((acc, item) => {
        acc[item.asin] = true;
        return acc;
      }, {});
      setFavorites(initialFavorites); // Update the state when props.data is ready
    }
  }, [props.data]);

  useEffect(() => {
    props.setPage(1);
  }, [props.filters])

  const addToFavorites = (product) => {
    setFavorites(prev => {
      return {
        ...prev,
        [product.asin]: true
      };
    });

    addToFavoritesDB(product);
  };

  const removeFromFavorites = (product) => {
    setFavorites(prev => {
      const newFavorites = { ...prev };
      newFavorites[product.asin] = false;
      return newFavorites;
    });

    removeFromFavoritesDB(product.asin);
  };

  const getWFSCost = async (product) => {
    fetch(`https://server.nepeto.com/moa_calculate_wfs/${product.asin}`)
      .then((response) => response.json())
      .then((result) => {
        product.wfs_cost = result;
        setRender(render => render + 1);
      });
  };

  function exportToCSV(objects, filename = 'data.csv') {
    const headers = ["title", "walmart_url", "asin", "upc", "az_price", "walmart_price", "az_category"]

    const rows = objects.map(obj => {
      return headers.map(header => header === "asin" ? `https://www.amazon.com/dp/${obj[header]}` : obj[header].toString().replaceAll(',', '')).join(',') + "," + (localStorage.getItem(obj.walmart_url + "Note") || "No Note");
    });

    const csvContent = ["Name,WM URL,Source URL,UPC,Buy Price,Sell (WM) Price,Category,Note", ...rows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);

    link.click();
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <div className="products-table-container" id="moaProductTable">
        {window.location.pathname === '/favorites' && <><span onClick={() => exportToCSV(props.data)} style={{ cursor: "pointer", border: "2px solid black", padding: "5px", borderRadius: "30px", marginLeft: "3%" }}><DownloadIcon style={{ marginBottom: "-6px" }} /> Export to CSV</span><br /><br /></>}
        <table className="products-table" style={{ width: '94vw', }}>
          <thead>
            <tr>
              <th>
                <span>Product</span><br />
                <span style={{ marginTop: "auto", float: "left" }}>Buy from</span>
                <span style={{ marginTop: "auto", float: "right", marginRight: "5.25%" }}>Sell on</span>
              </th>
              <th>Category</th>
              <th>AZ BSR</th>
              <th>ASIN/UPC</th>
              <th>Buy Price</th>
              <th>Sell (WM) Price</th>
              <th><Tooltip title="This is an estimate (15%). To get the most accurate calculation, click on the Marter icon to the right.">Referral Fees<span style={{fontSize: "75%"}}>(?)</span></Tooltip></th>
              <th>
                {sfChecked ? "Shipping Cost" : "WFS Cost"}<br />
                <label className="checkbox tooltip" style={{ fontSize: props.isMobile ? "100%" : "0.85vw", }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sfChecked}
                        sx={{ color: '#E6AF0A', '&.Mui-checked': { color: '#E6AF0A' }, transform: "scale(1.2)" }}
                        onChange={(e) => { setSFChecked(e.target.checked) }}
                      />
                    }
                    label={<strong style={{ fontFamily: 'RobotoSlab' }}>SF</strong>}
                  />
                </label>
              </th>
              <SortableTableHeader
                columnName={<>Profit</>}
                sortKey="profit"
                filters={props.filters}
                setFilters={props.setFilters}
              />
              <SortableTableHeader
                columnName={<>ROI</>}
                sortKey="roi"
                filters={props.filters}
                setFilters={props.setFilters}
              />
              {window.location.pathname === '/favorites' ? <th>Note</th> : <th>Dynamic<br />Keepa<br />Graph</th>}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              props.data.length === 0 && props.isLoading === false &&
              <tr>
                <td colSpan={12}>
                  <Typography style={{ textAlign: "center" }}>
                    <strong>No products.</strong>
                  </Typography>
                </td>
              </tr>
            }

            {props.isLoading ? (
              <tr>
                <td colSpan={12} style={{ color: '#E6AF0A', }}>
                  <LinearProgress sx={{ marginTop: '16px' }} color={'inherit'} />
                </td>
              </tr>
            ) : (
              props.data.map((product, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td className="data-row" style={{ width: '20vw', maxWidth: '20vw' }}>
                        <div style={{ display: "flex" }}>
                          <div className="amazonImage">
                            <p onClick={() => { window.open(`https://amazon.com/dp/${product.asin}?th=1&psc=1`); }} style={{ textAlign: "center", cursor: "pointer", margin: 'auto', }}>Amazon</p>
                            <img
                              alt="product_image"
                              className="hoverImageTable"
                              loading="lazy"
                              src={product.az_img}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/noimage.png";
                              }}
                              style={{
                                borderRadius: "5vw",
                                border: "5px solid rgba(230, 175, 10, 0.5)",
                                height: "4.5vw",
                                width: "4.5vw",
                                objectFit: "scale-down",
                                position: "relative",
                                zIndex: "5"
                              }}
                              onClick={() => {
                                window.open(`https://amazon.com/dp/${product.asin}?th=1&psc=1`);
                              }}
                            />
                          </div>

                          <span
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "10vw",
                              height: "10vh",
                              padding: "9px",
                              textAlign: "center",
                              margin: "auto",
                            }}
                          >
                            {product.title.split(' | ')[0].length > 40 ? `${product.title.split(' | ')[0].slice(0, 40)}...` : product.title.split(' | ')[0]}
                          </span>

                          <div className="sourceImage">
                            <p style={{ textAlign: "center", maxWidth: "4.5vw", cursor: "pointer", margin: 'auto', }}>Walmart</p>
                            <img
                              alt="product_image"
                              className="hoverImageTable"
                              loading="lazy"
                              src={product.walmart_img}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/assets/noimage.png";
                              }}
                              style={{
                                borderRadius: "5vw",
                                border: "5px solid rgba(230, 175, 10, 0.5)",
                                height: "4.5vw",
                                width: "4.5vw",
                                objectFit: "scale-down",
                                position: "relative",
                                zIndex: "5"
                              }}
                              onClick={() => {
                                window.open(product.walmart_url);
                              }}
                            />
                          </div>
                        </div>

                        {product.title.includes("Last Update") && (
                          <span
                            style={{
                              fontSize: "0.7vw",
                              marginLeft: "5%",
                              opacity: "0.7",
                              height: "0px",
                            }}
                          >
                            Found on{" "}
                            {new Date(product.title.split("Last Update: ")[1])
                              .toLocaleString()
                              .includes("Invalid")
                              ? product.title.split("Last Update: ")[1]
                              : new Date(
                                product.title.split("Last Update: ")[1]
                              ).toLocaleString()}
                          </span>
                        )}
                      </td>
                      <td id="categoryTt" className="data-row" style={{ width: '7vw', maxWidth: '7vw' }}>
                        {product.az_category === null || product.az_category === "" ? "N/A" : product.az_category}
                      </td>
                      <td id="bsrTt" className="data-row" style={{ width: '6vw', maxWidth: '6vw', }}>
                        #{product.sales_rank === null ? "N/A" : product.sales_rank}
                      </td>
                      <td id="identifiersTt" className="data-row" style={{ width: '6vw', maxWidth: '6vw', }}>
                        <a
                          href={"https://www.amazon.com/dp/" + product.asin + "?th=1&psc=1"}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: "underline", color: "#CC9901" }}
                        >
                          {product.asin}
                        </a>
                        <br />
                        {product.upc}
                      </td>
                      <td id="azPriceTt" className="data-row" style={{ width: '6vw', maxWidth: '6vw', }}>
                        ${product.az_price.toFixed(2)}
                      </td>
                      <td id="walmartPriceTt" className="data-row" style={{ width: '6vw', maxWidth: '6vw', }}>
                        ${product.walmart_price.toFixed(2)}
                      </td>
                      <td id="refFeesTt" className="data-row" style={{ width: '5vw', maxWidth: '5vw', }}>
                        ~${(product.walmart_price * 0.15).toFixed(2)}
                      </td>
                      <td id="wfsTt" className="data-row" render={render}>
                        {sfChecked ? (
                          <FormControl
                            sx={{
                              m: 1,
                              '& .MuiInput-underline:before': {
                                borderBottomColor: '#E6AF0A', // Default border color
                              },
                              '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottomColor: '#E6AF0A', // Hover border color
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#E6AF0A', // Focused border color
                              },
                            }}
                            variant="standard"
                          >
                            <InputLabel
                              htmlFor={`${index}-cost`}
                              style={{ color: "#CC9901", fontFamily: "RobotoSlab" }}
                            >
                              Shipping Cost
                            </InputLabel>
                            <Input
                              id={`${index}-cost`}
                              type="number"
                              size="small"
                              style={{ color: "#b38601", fontFamily: "RobotoSlab", width: "5vw" }}
                              value={product.sf_cost === undefined ? 0 : product.sf_cost}
                              inputProps={{ min: 0, step: 1 }}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Typography style={{ color: "#b38601", fontFamily: "RobotoSlab" }}>$</Typography>
                                </InputAdornment>
                              }
                              onChange={(e) => {
                                props.data.forEach((product) => product.sf_cost = parseFloat(e.target.value));
                                setRender(render => render + 1);
                              }}
                            />
                          </FormControl>

                        ) : (
                          product.hasOwnProperty("wfs_cost") && product.wfs_cost === -1 ? (
                            <CircularProgress size={20} thickness={5} style={{ marginLeft: '8px', color: 'inherit', }} />
                          ) : (
                            !(product.hasOwnProperty("wfs_cost") && product.wfs_cost > 0) ?
                              <Box>
                                <Button
                                  className="calcWfsBtn"
                                  onClick={() => {
                                    product.wfs_cost = -1;
                                    setRender(render => render + 1);
                                    getWFSCost(product);
                                  }}
                                  style={{
                                    fontSize: "80%",
                                    textTransform: 'none',
                                    fontFamily: 'RobotoSlab',
                                    color: '#b38601',
                                    width: '7vw',
                                    borderRadius: '20px',
                                    backgroundColor: "rgba(230,175,10,0.2)",
                                  }}
                                ><span>Calculate WFS</span>
                                </Button>
                              </Box>
                              :
                              "-$" + product.wfs_cost.toFixed(2)
                          )
                        )
                        }
                      </td>
                      <td id="grossProfitTt" className="data-row bg" style={{ width: '5vw', maxWidth: '5vw', }}>
                        ${(product.walmart_price - product.az_price - (product.walmart_price * 0.15) - (sfChecked ? (product.sf_cost ?? 0) : (product.hasOwnProperty("wfs_cost") && product.wfs_cost > 0 && product.wfs_cost))).toFixed(2)}
                      </td>
                      <td id="roiTt" className="data-row bg" style={{ width: '5vw', maxWidth: '5vw', }}>
                        {(((product.walmart_price - product.az_price - (product.walmart_price * 0.15) - (sfChecked ? (product.sf_cost ?? 0) : (product.hasOwnProperty("wfs_cost") && product.wfs_cost > 0 && product.wfs_cost))) / product.az_price) * 100).toFixed(2)}%
                      </td>
                      {window.location.pathname === '/favorites' ?
                        <td id="noteTt" className="data-row" style={{ width: "7vw", maxWidth: "7vw", zIndex: "9999 !important", backgroundColor: "rgb(255 230 94)",color: "black" }}>
                          <textarea onChange={(e) => localStorage.setItem(product.walmart_url + "Note", e.target.value)} placeholder={localStorage.getItem(product.walmart_url + "Note")} rows={6} style={{height: "80%", width: "80%", resize: "none"}}/>
                        </td> :
                        <td id="dkgTt" className="data-row" style={{ width: "7vw", maxWidth: "7vw", zIndex: "9999 !important", }}>
                          <img
                            loading="lazy"
                            onClick={() => setDKGAsin(product.asin.split("/")[product.asin.split("/").length - 1])}
                            src={
                              DKGasin === null ?
                                `https://graph.keepa.com/?asin=${product.asin.split("/")[product.asin.split("/").length - 1]}&domain=com&salesrank=${+bsr_state}&amazon=${+az_state}&new=${+new_state}&bb=${+bb_state}&fba=${+fba_state}&range=${range_state}`
                                :
                                '/assets/dkg_loading.png'
                            }
                            alt="keepahist"
                            style={{ position: "relative", border: "1px solid gray", width: "-webkit-fill-available", margin: 5 }}
                            className="keepagrapha2w"
                          />
                        </td>}
                      <td id="actionTt" className="data-row" style={{ width: "8vw", maxWidth: "8vw", }}>
                        <Box>
                          <CostumTooltip
                            arrow
                            placement="top"
                            title={
                              <React.Fragment>
                                <Typography color="#CC9901" style={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'RobotoSlab', }}>
                                  {favorites.hasOwnProperty(product.asin) && favorites[product.asin] ? "Remove from Favorites" : "Add to Favorites"}
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            {favorites.hasOwnProperty(product.asin) && favorites[product.asin] ? (
                              <FavoriteIcon fontSize={"large"} sx={{ cursor: 'pointer', color: "#E6AF0A" }} onClick={() => removeFromFavorites(product)} />
                            ) : (
                              <FavoriteBorderIcon fontSize={"large"} sx={{ cursor: 'pointer', color: "#E6AF0A" }} onClick={() => addToFavorites(product)} />
                            )}
                          </CostumTooltip>
                        </Box>
                        <div
                          style={{ display: "flex", marginTop: "1px", justifyContent: "center" }}
                        >
                          <img
                            className="graphicon"
                            alt="marter"
                            onClick={() => {setWmitemId(product.walmart_url.split("?")[0].split("#")[0].split("/").pop()); setWmitemPrice(product.az_price)}}
                            src={"/assets/marter.png"}
                            style={{
                              width: "1.5vw",
                              marginTop: "1px",
                              marginLeft: "3px",
                              height: "1.5vw",
                              borderRadius: "50%",
                              border: "2px solid rgba(0,0,0,0.6)",
                              padding: "3px",
                              boxShadow: "0px 0px 10px 0px orange"
                            }}
                          />&emsp;
                          <img
                            className="graphicon"
                            alt="keepa"
                            onClick={() => window.open(`https://keepa.com/#!product/1-${product.asin.split("/")[product.asin.split("/").length - 1]}}`, "_blank")}
                            src={"/assets/keepa.png"}
                            style={{
                              width: "1.5vw",
                              marginTop: "1px",
                              marginLeft: "3px",
                              height: "1.5vw",
                              borderRadius: "50%",
                              border: "2px solid gray",
                              padding: "3px",
                              boxShadow: "0px 0px 2px 0px gray"
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                )
              })
            )}
          </tbody>
          {window.location.pathname !== '/favorites' ? <tfoot>
            {!props.isLoading && (
              <tr>
                <td colSpan={12} style={{ border: "none" }}>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: 15, }}>
                    <Button
                      variant={props.page === 1 ? "outlined" : "contained"}
                      onClick={() => { props.setPage(p => p - 1); window.location.href = '#'; }}
                      disabled={props.page === 1}
                      style={{
                        textTransform: 'none',
                        fontFamily: 'RobotoSlab',
                        color: props.page === 1 ? '#9e9e9e' : '#FCF8F3',
                        borderRadius: '20px',
                        backgroundColor: props.page === 1 ? '#FCF8F3' : '#E6AF0A',
                        border: `1px solid ${props.page === 1 ? '#9e9e9e' : '#FCF8F3'}`,
                      }}
                    >
                      Previous Page
                    </Button>
                    <span>Page {props.page}</span>
                    <Button
                      variant={"contained"}
                      onClick={() => { props.setPage(p => p + 1); window.location.href = '#'; }}
                      style={{
                        textTransform: 'none',
                        fontFamily: 'RobotoSlab',
                        color: ((props.page) * 10) > props.data.length ? '#9e9e9e' : '#FCF8F3',
                        borderRadius: '20px',
                        backgroundColor: ((props.page) * 10) > props.data.length ? '#FCF8F3' : '#E6AF0A',
                        border: `1px solid ${((props.page) * 10) > props.data.length ? '#9e9e9e' : '#FCF8F3'}`,
                      }}
                    >
                      Next Page
                    </Button>
                  </div>
                </td>
              </tr>
            )}
          </tfoot> : <br />}
        </table>
      </div>

      <DynamicKeepaGraphPopup
        asin={DKGasin}
        range_state={range_state}
        bsr_state={bsr_state}
        az_state={az_state}
        new_state={new_state}
        bb_state={bb_state}
        fba_state={fba_state}
        setDKGAsin={setDKGAsin}
        setRange={setRange}
        setBsr={setBsr}
        setAz={setAz}
        setNew={setNew}
        setBb={setBb}
        setFba={setFba}
      />
      
      {wmitemId && <DraggablePopup itemId={wmitemId} setWmitemId={setWmitemId} price={wmitemPrice} />}
    </>
  );
}

export default ProductsTable;