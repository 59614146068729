import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, Button, Switch, Box, Grid, createTheme, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getUser } from '../../utils/authService';

// const CustomSwitch = styled(Switch)(({ theme }) => ({
//   '& .MuiSwitch-switchBase.Mui-checked': {
//     color: '#E6AF0A',
//     '&:hover': {
//       backgroundColor: alpha('#E6AF0A', theme.palette.action.hoverOpacity),
//     },
//   },
//   '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
//     backgroundColor: '#E6AF0A',
//   },
// }));

const Pricing = (props) => {
  const [subscribed, setSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // const handleToggle = () => {
  //   props.setIsYearly(!props.isYearly);
  // };

  useEffect(() => {
    const checkAccess = async () => {
      setIsLoading(true);
      fetch(`https://server.nepeto.com/moa_sub_check/${getUser()}/`, { mode: "cors", })
        .then((response) => response.json())
        .then((result) => {
          setSubscribed(result);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    checkAccess();
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4, }}>
        <Typography variant="h2" component="h1" gutterBottom align="center" style={{ fontFamily: 'RobotoSlab', color: '#E6AF0A', }}>
          Pricing
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Grid item style={{ display: "flex", flexDirection: "row" }}>
          {isLoading ? (
            <Box style={{ display: 'flex', justifyContent: 'center', }}>
              <CircularProgress style={{ color: '#E6AF0A' }} />
            </Box>
          ) : (
            <Card style={{ maxWidth: 350, backgroundColor: '#FCF8F3', margin: 'auto', textAlign: 'center', borderRadius: '15px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', }}>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom style={{ fontFamily: 'RobotoSlab', }}>
                  MOA Full Access
                </Typography>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                  <Typography style={{ fontFamily: 'RobotoSlab', }}>Monthly</Typography>
                  <CustomSwitch checked={props.isYearly} onChange={handleToggle} style={{ color: '#E6AF0A', }} />
                  <Typography style={{ fontFamily: 'RobotoSlab', }}>Yearly</Typography>
                </Box> */}

                {props.isYearly && <Typography style={{ fontFamily: 'RobotoSlab', fontSize: 14, }}>* billed yearly</Typography>}
                <Typography style={{ color: '#E6AF0A', fontFamily: 'RobotoSlab', fontSize: '2.5rem', fontWeight: 'bold', marginBottom: 5, }}>
                  <span style={{fontSize: "50%", color: "red", textDecoration: "line-through"}}>$59.99</span>${props.isYearly ? 62 : 19.99}<Typography component="span" variant="h6">/month</Typography>
                </Typography>
                <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                  <CheckCircleIcon style={{ color: '#E6AF0A', }} sx={{ mr: 1 }} /> 7-Day Free Trial
                </Typography>
                <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                  <CheckCircleIcon style={{ color: '#E6AF0A', }} sx={{ mr: 1 }} /> Locked-In Price
                </Typography>
                <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                  <CheckCircleIcon style={{ color: '#E6AF0A', }} sx={{ mr: 1 }} /> Full Access to MOA
                </Typography>
                <Button
                  variant="contained"
                  disabled={subscribed}
                  onClick={() => {
                    props.setIsFlipped(true);
                    window.location.href = props.isAuth ? (props.isYearly ?
                      `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=102274&page-template=19169&language=en&currency=USD&billing-email=${getUser()}` :
                      `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=102274&page-template=19169&language=en&currency=USD&billing-email=${getUser()}`) : '/login'
                  }}
                  style={{
                    textTransform: 'none',
                    fontFamily: 'RobotoSlab',
                    color: '#FCF8F3',
                    borderRadius: '20px',
                    backgroundColor: subscribed ? '#9e9e9e' : '#E6AF0A',
                    border: '1px solid #FCF8F3',
                    marginTop: 15
                  }}
                >
                  {subscribed ? 'Current Plan' : props.isAuth ? 'Get Started' : "Login"}
                </Button>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <br/><br/>  
      <h1 style={{textAlign: "center"}}>Roadmap</h1>
      <div class="roadmap">
        <div class="roadmap-step completed">
          <div class="step-icon">✓</div>
          <div class="step-content">
            <h3>Step 1: Beta Release</h3>
            <p>A2W, Favorites, Core.</p>
          </div>
        </div>
        <div class="roadmap-step">
          <div class="step-icon">2</div>
          <div class="step-content">
            <h3>Step 2: More Suppliers</h3>
            <p>Target, Kohl's, Etc.</p>
          </div>
        </div>
        <div class="roadmap-step">
          <div class="step-icon">3</div>
          <div class="step-content">
            <h3>Step 3: Live Search</h3>
            <p>Like Nepeto's :)</p>
          </div>
        </div>
        <div class="roadmap-step">
          <div class="step-icon">4</div>
          <div class="step-content">
            <h3>Step 4: TBD</h3>
            <p>It will be worth the wait.</p>
          </div>
        </div>
      </div>

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'RobotoSlab', }}>
          Have questions? Contact our support team for more information.<br />
          Email: info@getmarter.com
        </Typography>
      </Box>
    </Container>
  );
};

export default Pricing;