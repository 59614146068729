import { Stack, styled, Box, Button, Typography, Tooltip, tooltipClasses, Menu, MenuItem, TextField, InputAdornment, IconButton } from "@mui/material";
import React, { useState } from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DoneIcon from '@mui/icons-material/Done';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import ModalForSettings from "./ModalForSettings";

const CostumTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCF8F3',
    color: '#b38601',
    maxWidth: "10vw",
    border: '2px solid #b38601',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#b38601',
  },
});

function FilterBar(props) {
  const [modalSetting, setModalSetting] = useState("");
  const [openModalSetting, setOpenModalSetting] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State to track menu visibility
  const [searchText, setSearchText] = useState(props.filters.hasOwnProperty("search") ? props.filters.search : "");
  const [categories, setCategories] = useState({
    "Amazon Devices & Accessories": false,
    "Amazon Renewed": false,
    "Appliances": false,
    "Arts, Crafts & Sewing": false,
    "Automotive": false,
    "Baby": false,
    "Beauty & Personal Care": false,
    "Books": false,
    "Camera & Photo Products": false,
    "CDs & Vinyl": false,
    "Cell Phones & Accessories": false,
    "Climate Pledge Friendly": false,
    "Clothing, Shoes & Jewelry": false,
    "Collectible Coins": false,
    "Computers & Accessories": false,
    "Electronics": false,
    "Grocery & Gourmet Food": false,
    "Handmade Products": false,
    "Health & Household": false,
    "Home & Kitchen": false,
    "Industrial & Scientific": false,
    "Kitchen & Dining": false,
    "Movies & TV": false,
    "Musical Instruments": false,
    "Office Products": false,
    "Our Brands": false,
    "Patio, Lawn & Garden": false,
    "Pet Supplies": false,
    "Software": false,
    "Sports & Outdoors": false,
    "Tools & Home Improvement": false,
    "Toys & Games": false,
    "Unique Finds": false,
    "Video Games": false,
  });

  const toggleCategory = (name) => {
    if (name === 'all') {
      const newSelectAll = !selectAll;
      setCategories((prevCategories) =>
        Object.fromEntries(
          Object.keys(prevCategories).map((key) => [key, newSelectAll])
        )
      );
      setSelectAll(newSelectAll); // Update the selectAll state
    } else {
      setCategories((prevCategories) => ({
        ...prevCategories,
        [name]: !prevCategories[name],
      }));
    }
  };

  const handleClickCategory = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleCloseCategory = () => {
    props.setFilters((filters) => ({
      ...filters,
      category: Object.keys(categories).sort().filter(key => categories[key] === true),
    }))
    if (Object.keys(categories).sort().filter(key => categories[key] === true).length === 0) {
      let tempFilters = { ...props.filters };
      delete tempFilters['category'];
      props.setFilters(tempFilters);
    }

    setAnchorEl(null); // Close the menu
  };

  const filterBtnStyle = {
    textTransform: 'none',
    fontFamily: 'RobotoSlab',
    color: '#b38601',
    width: '7vw',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderRadius: '20px 20px 10px 10px',
  }

  const hasProperty = (property) => {
    return props.filters && props.filters.hasOwnProperty(property)
  }

  return (
    <div className="filterBar">
      <div className="products-table-container">
        <Stack direction="row" spacing={2} sx={{ flexGrow: 1 }}>
          { /* Reset Filters */}
          <CostumTooltip
            arrow
            title={
              <React.Fragment>
                <Typography color="#E6AF0A" style={{ fontWeight: 'bold', fontSize: 12, fontFamily: 'RobotoSlab', }}>Reset Filters</Typography>
              </React.Fragment>
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width={props.isMobile ? "17vw" : "3.5vw"}
              height={"4.4vh"}
              viewBox="0 0 120.000000 120.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{ cursor: "pointer" }}
              onClick={() => window.location.reload()}
            >
              <g
                transform="translate(0.000000,111.000000) scale(0.0900000,-0.086000)"
                fill="#b38601"
                stroke="none"
              >
                <path d="M813 1266 c-77 -25 -153 -94 -183 -167 l-12 -29 -247 0 c-266 0 -291 -5 -291 -53 0 -27 -6 -17 184 -277 l151 -205 5 -253 c5 -236 6 -255 24 -268 32 -23 50 -17 142 51 63 46 92 74 101 97 7 20 12 97 12 199 l1 166 58 82 57 82 51 -6 c30 -3 74 1 108 10 270 71 306 430 56 555 -56 28 -157 36 -217 16z m182 -82 c85 -40 145 -146 129 -231 -33 -185 -248 -257 -378 -127 -107 107 -78 286 56 353 52 26 145 28 193 5z m-384 -251 c8 -64 45 -130 99 -176 l40 -34 -18 -24 c-11 -13 -40 -55 -66 -92 l-46 -68 0 -178 0 -179 -55 -41 c-30 -22 -57 -41 -60 -41 -3 0 -5 96 -5 213 0 185 -2 217 -18 247 -10 19 -82 122 -160 228 -78 106 -142 195 -142 198 0 2 96 3 213 2 l212 -3 6 -52z" />
                <path d="M800 1070 c-8 -15 -5 -25 20 -53 l29 -34 -24 -23 c-28 -26 -32 -61 -11 -79 17 -14 41 -5 70 28 l19 21 34 -30 c28 -25 38 -28 53 -20 27 15 25 39 -7 72 l-27 28 27 28 c29 31 34 51 15 70 -18 18 -33 15 -68 -14 l-31 -27 -28 27 c-32 31 -56 33 -71 6z" />
              </g>
            </svg>
            {/* <FilterAltOffIcon onClick={() => props.setFilters({ isFBM: true, })} style={{ fontSize: 36, cursor: "pointer" }} /> */}
          </CostumTooltip>

          {/* Search input */}
          <TextField
            focused
            sx={{
              width: '12vw',
              '& .MuiOutlinedInput-root': {
                color: '#b38601',
                '& fieldset': {
                  borderColor: '#b38601',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: '#b38601',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#b38601',
                  borderWidth: '1px',
                },
              },
            }}
            onChange={(e) => { setSearchText(e.target.value); }}
            variant="outlined"
            placeholder={"Search Product"}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      props.setFilters((filters) => ({
                        ...filters,
                        search: searchText,
                      }))
                    }
                    edge="end"
                    sx={{ color: '#b38601' }} // Set color for the IconButton
                  >
                    <SearchSharpIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Categories select */}
          <Box sx={{ fontSize: 13 }}>
            <Button
              onClick={handleClickCategory}
              style={{
                ...filterBtnStyle,
                borderBottomWidth: hasProperty("category") ? "3px" : "2px",
                borderBottomColor: hasProperty("category") ? "green" : "#E6AF0A",
                backgroundColor: hasProperty("category") ? "#fcf8f3" : "transparent",
              }}
            >
              <ArrowRightIcon />
              <span>Category</span>
            </Button>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseCategory}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            PaperProps={{
              style: {
                maxHeight: 350, // Set a maximum height
              },
            }}
          >
            <MenuItem onClick={() => toggleCategory('all')} style={{ display: 'flex', flexDirection: 'row', gap: '16px', listStyleType: 'none', padding: 0, cursor: 'pointer', }}>
              <Box
                component={selectAll ? DoneIcon : 'span'}
                sx={{ width: 14, height: 14, flexShrink: 0, borderRadius: '3px', ml: 1, mt: '2px', }}
                style={{ border: '1px solid #b38601', color: '#b38601', }}
              />
              <Box><span style={{ color: '#b38601' }}>{selectAll ? 'Unselect All' : 'Select All'}</span></Box>
            </MenuItem>
            {Object.keys(categories).map((name, key) => (
              <MenuItem key={key} onClick={() => toggleCategory(name)} style={{ display: 'flex', flexDirection: 'row', gap: '16px', listStyleType: 'none', padding: 0, cursor: 'pointer', }}>
                <Box
                  component={categories[name] ? DoneIcon : 'span'}
                  sx={{ width: 14, height: 14, flexShrink: 0, borderRadius: '3px', ml: 1, mt: '2px', }}
                  style={{ border: '1px solid #b38601', color: '#b38601', }}
                />
                <Box><span style={{ color: '#b38601' }}>{name}</span></Box>
              </MenuItem>
            ))}
          </Menu>

          {/* AZ BSR */}
          <Box sx={{ fontSize: 13 }}>
            <Button onClick={() => { setModalSetting("bsr"); setOpenModalSetting(true); }}
              style={{
                ...filterBtnStyle,
                borderBottomWidth: hasProperty("bsr") ? "3px" : "2px",
                borderBottomColor: hasProperty("bsr") ? "green" : "#E6AF0A",
                backgroundColor: hasProperty("bsr") ? "#fcf8f3" : "transparent",
              }}
            ><span>AZ BSR</span>
            </Button>
          </Box>

          {/* AZ Price */}
          <Box sx={{ fontSize: 13 }}>
            <Button onClick={() => { setModalSetting("az_price"); setOpenModalSetting(true); }}
              style={{
                ...filterBtnStyle,
                borderBottomWidth: hasProperty("az_price") ? "3px" : "2px",
                borderBottomColor: hasProperty("az_price") ? "green" : "#E6AF0A",
                backgroundColor: hasProperty("az_price") ? "#fcf8f3" : "transparent",
              }}
            ><span>Buy Price</span>
            </Button>
          </Box>

          {/* Walmart Price */}
          <Box sx={{ fontSize: 13 }}>
            <Button onClick={() => { setModalSetting("walmart_price"); setOpenModalSetting(true); }}
              style={{
                ...filterBtnStyle,
                borderBottomWidth: hasProperty("walmart_price") ? "3px" : "2px",
                borderBottomColor: hasProperty("walmart_price") ? "green" : "#E6AF0A",
                backgroundColor: hasProperty("walmart_price") ? "#fcf8f3" : "transparent",
              }}
            ><span>Sell Price</span>
            </Button>
          </Box>

          {/* Gross Profit */}
          <Box sx={{ fontSize: 13 }}>
            <Button onClick={() => { setModalSetting("gross_profit"); setOpenModalSetting(true); }}
              style={{
                ...filterBtnStyle,
                borderBottomWidth: hasProperty("gross_profit") ? "3px" : "2px",
                borderBottomColor: hasProperty("gross_profit") ? "green" : "#E6AF0A",
                backgroundColor: hasProperty("gross_profit") ? "#fcf8f3" : "transparent",
              }}
            ><span>Profit</span>
            </Button>
          </Box>

          {/* ROI */}
          <Box sx={{ fontSize: 13 }}>
            <Button onClick={() => { setModalSetting("roi"); setOpenModalSetting(true); }}
              style={{
                ...filterBtnStyle,
                borderBottomWidth: hasProperty("roi") ? "3px" : "2px",
                borderBottomColor: hasProperty("roi") ? "green" : "#E6AF0A",
                backgroundColor: hasProperty("roi") ? "#fcf8f3" : "transparent",
              }}
            ><span>ROI</span>
            </Button>
          </Box>
        </Stack>
      </div>

      {/* Settings Modal */}
      <ModalForSettings
        filters={props.filters}
        setFilters={props.setFilters}
        modalSetting={modalSetting}
        setModalSetting={setModalSetting}
        isMobile={props.isMobile}
        supplierType={"retail"}
        open={openModalSetting}
        setOpen={setOpenModalSetting}
      />
    </div>
  );
}

export default FilterBar;
