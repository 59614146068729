import React from 'react';
import { Typography, Container, ThemeProvider, createTheme, Box, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './HomePage.css';
import walmartIcon from './Components/walmartIcon.svg';
import amazonIcon from './Components/amazonIcon.svg';
import profitIcon from './Components/profitIcon.svg';

const theme = createTheme({
  palette: {
    primary: { main: '#CC9901', },
  },
  typography: {
    fontFamily: 'Roboto Slab',
  },
});

const FeatureItem = ({ icon, title, text }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
    <Box className="iconPopup">
      {icon}
    </Box>
    <Typography variant="h5" sx={{ mt: 2, mb: 1, color: '#CC9901' }}>
      {title}
    </Typography>
    <Typography variant="h6" sx={{ color: '#CC9901' }}>
      {text}
    </Typography>
  </Box>
);

const HomePage = () => {
  const features = [
    {
      icon: <img src={amazonIcon} alt="amazonIcon" className="block-icon block-icon-img" />,
      title: "Find Profitable Products",
      text: "Use advanced tools to identify the best-selling items on Amazon that fit your business goals."
    },
    {
      icon: <img src={walmartIcon} alt="walmartIcon" className="block-icon block-icon-img" />,
      title: "Expand to Walmart Effortlessly",
      text: "Transfer product details and get set up for success on Walmart's thriving marketplace."
    },
    {
      icon: <img src={profitIcon} alt="profitIcon" className="block-icon block-icon-img" />,
      title: "Boost Your Profitability",
      text: "Maximize your opportunities by tapping into two of the biggest e-commerce platforms in the world."
    },
  ];

  return (
    <ThemeProvider theme={theme}>

      <Box sx={{ backgroundColor: '#FCF8F3', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4, marginTop: "10vh" }}>
          <Typography variant="h3" align="center" color="#CC9901" fontFamily="'RobotoSlab', sans-serif">
            Sourcing Products for Walmart
          </Typography>
          <Typography variant="h4" align="center" color="#E6AF0A" fontFamily="'RobotoSlab', sans-serif" fontWeight={900}>
          Has Never Been EASIER
          </Typography>
          <br />
          <video
            className="tableImage"
            autoPlay
            loop
            muted
            poster="/assets/table_1.png"
            style={{ maxWidth: '90%', height: 'auto', mt: 2, border: '2px solid #E6AF0A', borderRadius: '20px', cursor: "default" }}
          >
            <source
              src="/assets/test.mp4"
              type="video/mp4"
            />
          </video>
          <br />
        </Container>
      </Box>
    
      <Box sx={{ minHeight: '60vh', padding: '2rem', backgroundColor: '#F5E6D3', display: 'flex', justifyContent: 'center', }}>
        <Grid container spacing={4} alignItems="center" sx={{ maxWidth: '70vw', }}>
        <h1 style={{textAlign: "center"}}>Roadmap</h1>
      <div class="roadmap">
        <div class="roadmap-step completed">
          <div class="step-icon">✓</div>
          <div class="step-content">
            <h3>Step 1: Beta Release</h3>
            <p>A2W, Favorites, Core.</p>
          </div>
        </div>
        <div class="roadmap-step">
          <div class="step-icon">2</div>
          <div class="step-content">
            <h3>Step 2: More Suppliers</h3>
            <p>Target, Kohl's, Etc.</p>
          </div>
        </div>
        <div class="roadmap-step">
          <div class="step-icon">3</div>
          <div class="step-content">
            <h3>Step 3: Live Search</h3>
            <p>Like Nepeto's :)</p>
          </div>
        </div>
        <div class="roadmap-step">
          <div class="step-icon">4</div>
          <div class="step-content">
            <h3>Step 4: TBD</h3>
            <p>It will be worth the wait.</p>
          </div>
        </div>
      </div>
        </Grid>
      </Box>

      <footer style={{ backgroundColor: '#E6AF0A', padding: 20 }}>
        <Typography variant="h6" align="center" gutterBottom style={{ color: '#F5E6D3' }} fontFamily="'RobotoSlab', sans-serif">
          Email: info@getmarter.com
        </Typography>
        <Typography variant="subtitle1" align="center" style={{ color: '#F5E6D3' }} fontFamily="'RobotoSlab', sans-serif">
          © {new Date().getFullYear()} MOA
        </Typography>
      </footer>
    </ThemeProvider>
  );
};

export default HomePage;