import { useNavigate } from 'react-router-dom';

import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, MenuItem, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../moa.svg';
import { signOut } from '../utils/authService';
import { useEffect, useState } from 'react';
import { AccountCircle } from '@mui/icons-material';

function Navbar(props) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navbarBtnStyle = {
    my: 3,
    mx: 1,
    borderRadius: 20,
    color: '#b38601',
    display: 'block',
    fontFamily: 'RobotoSlab',
    fontSize: 18,
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: '#FCF8F3', }} style={{ height: '10vh', }}>
      <Container maxWidth="xl" style={{ paddingRight: '10vw', paddingLeft: '10vw' }}>
        <Toolbar disableGutters>
          <img src={logo} alt={'MarterOA'} style={{ maxHeight: 50, cursor: 'pointer', }} onClick={() => navigate('/')} />

          {/* Folded */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="b38601"
            >
              <MenuIcon color='#b38601' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuItem onClick={() => navigate('/pricing')}>
                <Typography sx={{ textAlign: 'center', color: '#b38601', fontFamily: 'RobotoSlab', }}>Pricing</Typography>
              </MenuItem>
            </Menu>
          </Box>

          {/* Un-Folded */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', }}>
          <Button sx={{ ...navbarBtnStyle }} onClick={() => navigate('/pricing')}>
              Pricing
            </Button>
            <Button sx={{ ...navbarBtnStyle }} onClick={() => {
              if (!props.isAuth) {
                navigate('/login');
              } else {
                navigate('/favorites');
              }
            }}>
              Favorites
            </Button>
            <Button sx={{ ...navbarBtnStyle }} onClick={() => navigate('/products')}>
              Products
            </Button>
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, }}>
            {props.isAuth ? (
              <>
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle style={{ color: '#b38601', fontSize: 40 }} />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => { handleClose(); navigate('/account') }}>Account details</MenuItem>
                    <MenuItem onClick={() => { handleClose(); signOut(); props.setIsAuth(false); navigate('/') }}>Logout</MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <Button sx={{ ...navbarBtnStyle, px: 4, color: '#FCF8F3', backgroundColor: '#E6AF0A', fontSize: 14 }} onClick={() => navigate('/login')} variant="contained">
                  LOGIN
                </Button>
              </>
            )}

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
