const USER_KEY = 'marterOAEmail';

export const signIn = (email) => {
  localStorage.setItem(USER_KEY, email);
  return true;
};

export const signOut = async () => {
  localStorage.removeItem(USER_KEY);
};

export const getUser = () => {
  return localStorage.getItem(USER_KEY);
};

export const isUserAuthenticated = () => {
  const user = getUser();
  const loggedIn = (user !== undefined && user !== "undefined" && user !== null && user) ? true : false;
  return loggedIn
};