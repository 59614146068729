import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../utils/authService';
import ProductsTable from '../../components/ProductsTable';
import FilterBar from '../../components/FilterBar';

// Create a vintage theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#CC9901',
    },
    secondary: {
      main: '#B88C08',
    },
    background: {
      default: '#f5e6d3',
      paper: '#efe1c6',
    },
    text: {
      primary: '#4a3728',
      secondary: '#CC9901',
    },
  },
  typography: {
    fontFamily: 'RobotoSlab',
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
  },
});

const Favorites = (props) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const fetchFavorites = async () => {
      setLoading(true);
      fetch(`https://server.nepeto.com/get_moa_user/${getUser()}/`)
        .then((response) => response.json())
        .then((result) => {
          if (result.ok) {
            setData(result.data.favorites);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
    
    fetchFavorites();
}, []);

if (!props.isAuth) {
  navigate('/login');
}

return (
  <ThemeProvider theme={theme}>
    <Container maxWidth="md" sx={{ mt: 4, mb: 1, p: 3, borderRadius: 2 }}>
      <Typography variant="h4" gutterBottom align="center" color="primary">
        Your Favorites
      </Typography>
      <Typography variant="span" gutterBottom align="center" color="primary" style={{textAlign: "center", margin: "auto", display: "block"}}>
      Click the heart icon in the right column on the products page to add items to your favorites.
      </Typography>
      <Typography variant="span" gutterBottom align="center" color="darkorange" style={{textAlign: "center", margin: "auto", display: "block"}}>
      Not live data – they are snapshots from when they were added to favorites
      </Typography>
    </Container>

    <ProductsTable
      data={data}
      setPage={setPage}
      page={page}
      isMobile={props.isMobile}
      setFilters={setFilters}
      filters={filters}
      isLoading={loading}
    />
  </ThemeProvider>
);
};

export default Favorites;