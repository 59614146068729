import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getUser, isUserAuthenticated } from "../../utils/authService";
import ProductsTable from '../../components/ProductsTable';
import FilterBar from '../../components/FilterBar';

function Amazon2Walmart(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [filters, setFilters] = useState({ search: urlParams.has("asin") ? urlParams.get('asin') : "" });
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    const loadProducts = async () => {
      fetch(`https://server.nepeto.com/moa_load_a2w_products_by_page/${getUser()}/${page}/${JSON.stringify(filters)}/`)
        .then((response) => response.json())
        .then((result) => {
          if (result === "No permissions") {
            navigate("/pricing");
          } else {
            if (result.length > 0 && !props.isMobile && !(new Date(result[0].title.split("Last Update: ")[1]).toDateString().includes("Invalid"))) {
              let filteredData = result.filter(product => (((new Date() - new Date(product.title.split("Last Update: ")[1])) / 1000 / 60 / 60) <= 168))
              if (filteredData.length > 0) {
                setData(filteredData.sort((a, b) => 0.5 - Math.random()));
                setIsProductsLoading(false);
              } else {
                setPage(page => page + 1);
              }
            } else {
              setData(result);
              setIsProductsLoading(false);
            }
          }
        })
        .catch(() => {
          setIsProductsLoading(false);
        });
    };

    const checkAccess = async () => {
      setIsProductsLoading(true);
      fetch(`https://server.nepeto.com/moa_sub_check/${getUser()}/`)
        .then((response) => response.json())
        .then((result) => {
          setSubscribed(result);
          if (isUserAuthenticated() && result) {
            loadProducts();
          } else {
            window.location.href = "/pricing"
          }
        })
        .catch(() => {
        });
    };
    checkAccess();
  }, [page, filters, props.isMobile]);

  if (!isProductsLoading && !subscribed) {
    return (
      <div style={{ textAlign: "center", marginBottom: "80vh" }}>
        <Helmet>
          <title>MOA - Products Page</title>
          <meta
            name="description"
            content="Explore products. Discover profitable opportunities."
          />
          <meta
            name="keywords"
            content="amazon fba, product sourcing, a2w, products, leads, amazon flips, amazon fba products, fba products, fba sourcing"
          />
        </Helmet>
        <br />
        <br />
        <p
          style={{
            width: "80vw",
            margin: "auto",
            fontSize: "200%",
            boxShadow: "0px 0px 5px 1px #8c3e7c",
            padding: "2%",
          }}
        >
          To use MOA, sign up and subscribe to our plan.
          <br />
          <br />
          <button
            className="button is-rounded is-size-6-mobile is-size-6-tablet"
            style={{
              backgroundColor: "#32CD32",
              margin: "auto",
              color: "#ffffff",
            }}
            onClick={() => navigate('/login')}
          >
            Sign up for Free!
          </button>
        </p>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>MOA - Products Page</title>
        <meta
          name="description"
          content="Explore products. Discover profitable opportunities."
        />
        <meta
          name="keywords"
          content="amazon fba, product sourcing, a2w, leads, products, amazon flips, amazon fba products, fba products, fba sourcing"
        />
      </Helmet>
      <h1
        style={{
          fontSize: props.isMobile ? "3vh" : "2vw",
          fontWeight: "380",
          textAlign: "center",
          color: "#CC9901",
          marginBottom: props.isMobile && "1vh",
        }}
      >
        Products Page
      </h1>
      <FilterBar
        data={data}
        setFilters={setFilters}
        filters={filters}
      />

      <ProductsTable
        data={data}
        setPage={setPage}
        page={page}
        isMobile={props.isMobile}
        setFilters={setFilters}
        filters={filters}
        isLoading={isProductsLoading}
      />
    </>
  );
}
export default Amazon2Walmart;
