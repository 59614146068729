import React, { useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  ThemeProvider,
  createTheme,
  Checkbox,
  FormControlLabel,
  Link,
  Modal,
  InputAdornment,
  IconButton,
  styled
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../utils/authService';
import { sha256 } from '../../utils/helpers';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E6AF0A',
    },
    background: {
      default: '#FCF8F3',
    },
  },
  typography: {
    fontFamily: 'RobotoSlab',
  },
});

const VintageTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E6AF0A',
    },
    '&:hover fieldset': {
      borderColor: '#a67b45',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#E6AF0A',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#E6AF0A',
  },
});

const CustomButton = ({ children, ...props }) => (
  <Button
    variant="contained"
    style={{
      textTransform: 'none',
      fontFamily: 'RobotoSlab',
      color: '#FCF8F3',
      borderRadius: '20px',
      backgroundColor: '#E6AF0A',
      border: '1px solid #FCF8F3',
    }}
    {...props}
  >
    {children}
  </Button>
);

const VerifyPassModal = ({ open, handleClose, veriCode, setVeriCode, setModalMsg, setOpenModal, email, setIsAuth }) => {
  const [confirmationCode, setConfirmationCode] = useState(['', '', '', '', '']);
  const navigate = useNavigate();

  const handleCodeChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newCode = [...confirmationCode];
      newCode[index] = value;
      setConfirmationCode(newCode);

      // Move focus to the next input
      if (value && index < 4) {
        document.getElementById(`code-${index + 1}`).focus();
      }
    }
  };

  const verifyCode = async () => {
    const code = confirmationCode.join('');

    if (await sha256(code) === veriCode) {
      setVeriCode("GOOD");

      fetch(`https://server.nepeto.com/moa_start/${email}/none/`)
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            signIn(email);
            setIsAuth(true);
            handleClose();
            navigate('/products');
          } else {
            setModalMsg("Sign up failed");
            setOpenModal(true);
          }
        })
        .catch(() => {
        });
    } else {
      setModalMsg("Invalid code. Please try again.");
      setOpenModal(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '26vw',
          bgcolor: '#FCF8F3',
          borderRadius: 5,
          border: '4px solid rgba(126, 62, 27, 0.5)',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center' }}>
          Verify Email
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center' }}>
            Please enter your code from email here:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            {confirmationCode.map((digit, index) => (
              <TextField
                key={index}
                id={`code-${index}`}
                value={digit}
                onChange={(e) => handleCodeChange(index, e.target.value)}
                variant="outlined"
                inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                sx={{ width: '40px' }}
              />
            ))}
          </Box>
          <Button onClick={verifyCode} fullWidth variant="contained" sx={{ mt: 2, backgroundColor: "darkgreen" }}>
            Verify Code
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={handleClose} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: 'darkred',
              border: '1px solid #FCF8F3'
            }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const LoginSignUp = (props) => {
  const [email, setEmail] = useState('');
  const [veriCode, setVeriCode] = useState('NOCODE');

  const [modalMsg, setModalMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);

  const validateEmail = (email) => {
    const emailPattern = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setModalMsg('Invalid email');
      setOpenModal(true);
      return;
    }

    fetch(`https://server.nepeto.com/moa_verify_user/${email}/`)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setVeriCode(result);
          setOpenResetModal(true);
        } else {
          setModalMsg("User not exists. Please sign up or contact support for help.")
          setOpenModal(true);
        }
      })
      .catch(() => {
      });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            alignItems: 'center',
            py: 4,
            // backgroundImage: 'url("/assets/logo512 .png")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Container maxWidth="sm">
            <Box component="div" sx={{ mt: 1 }}>
              <Typography variant="h4" align="center" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
                Sign In
              </Typography>
              <VintageTextField
                margin="normal"
                required
                fullWidth
                id="emailSignIn"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <CustomButton fullWidth sx={{ mt: 3, mb: 2 }} onClick={handleLogin}>
                Sign In
              </CustomButton>
              <br /><br /><br /><br />
              <Typography variant="p" align="center" color="primary" gutterBottom sx={{ fontWeight: 'bold', textAlign: "center" }}>
                New here? No worries! Just enter your email address above, and we'll create your account!
              </Typography>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            {modalMsg}
          </Typography>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }} >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>

      <VerifyPassModal
        open={openResetModal}
        handleClose={() => setOpenResetModal(false)}
        veriCode={veriCode}
        setVeriCode={setVeriCode}
        setModalMsg={setModalMsg}
        setOpenModal={setOpenModal}
        email={email}
        setIsAuth={props.setIsAuth}
      />
    </>
  );
};

export default LoginSignUp;